<template>
    <li class="nav-item">
    <a v-if="href" class="nav-link active" :href="href"><slot></slot> {{ translatedTitle }}</a>
      <button v-else class="nav-link active w-100 text-start" :href="href"><slot></slot> {{ translatedTitle }}</button>
    </li>
  </template>
  
  <script>
  export default {
    props: {
      href: String,
      title: String,
      titleKey: String,
    },
    computed: {
      translatedTitle() {
        if (this.titleKey) {
          const translation = this.$t(this.titleKey);
          if (translation !== this.titleKey) {
            return translation;
          }
        }
        return this.title;
      },
    },
  }
  </script>
  