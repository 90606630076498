<template>
  <li class="nav-item px-2 dropdown">
    <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
      title="Cambio de tema">
      <i :class="themeIcon"></i>
    </a>
    <ul class="dropdown-menu dropdown-menu-end">
      <li><a class="dropdown-item" @click="setTheme('light')"><i class="bi bi-sun"></i> Light</a></li>
      <li><a class="dropdown-item" @click="setTheme('dark')"><i class="bi bi-moon-stars"></i> Dark</a></li>
      <li><a class="dropdown-item" @click="setTheme('auto')"><i class="bi bi-circle-half"></i> Auto</a></li>
    </ul>
  </li>
</template>
  
<script>
export default {
  name: 'ThemeSwitcher',
  data() {
    return {
      currentTheme: 'auto',
      themeIcon: 'bi bi-circle-half',
    }
  },
  watch: {
    currentTheme(newTheme) {
      this.updateThemeIcon(newTheme);
      this.applyTheme(newTheme);
      localStorage.setItem('selectedTheme', newTheme);
    }
  },
  created() {
    this.currentTheme = localStorage.getItem('selectedTheme') || 'auto';
    this.applyTheme(this.currentTheme);
    this.setupAutoThemeListener();
  },
  beforeUnmount() {
    if (this.prefersDarkMode) {
      this.prefersDarkMode.removeEventListener('change', this.handleSystemThemeChange);
    }
  },
  methods: {
    updateThemeIcon(theme) {
      switch (theme) {
        case 'light':
          this.themeIcon = 'bi bi-sun';
          break;
        case 'dark':
          this.themeIcon = 'bi bi-moon-stars';
          break;
        case 'auto':
          this.themeIcon = 'bi bi-circle-half';
          break;
      }
    },
    applyTheme(theme) {
      const body = document.body;
      if (theme === 'auto') {
        this.setAutoTheme();
      } else {
        body.setAttribute('data-bs-theme', theme);
      }
    },
    setAutoTheme() {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      document.body.setAttribute('data-bs-theme', prefersDarkMode ? 'dark' : 'light');
    },
    setupAutoThemeListener() {
      this.prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
      this.handleSystemThemeChange = (e) => {
        if (this.currentTheme === 'auto') {
          document.body.setAttribute('data-bs-theme', e.matches ? 'dark' : 'light');
        }
      };
      this.prefersDarkMode.addEventListener('change', this.handleSystemThemeChange);
    },
    setTheme(theme) {
      this.currentTheme = theme;
    }
  }
}
</script>
  