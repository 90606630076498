import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";

import { createApp } from 'vue'
import App from './App.vue'
import { store, i18n } from './store'


const app = createApp(App)
app.use(store)
app.use(i18n)
app.mount('#app')
