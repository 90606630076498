<template>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle active" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> {{
      translatedTitle }}
    </a>
    <ul class="dropdown-menu">
      <slot></slot>
    </ul>
  </li>
</template>
  
<script>
export default {
  name: 'NavbarDropdown',
  props: {
    title: String,
    titleKey: String,
  },
  computed: {
    translatedTitle() {
      if (this.titleKey) {
        const translation = this.$t(this.titleKey);
        if (translation !== this.titleKey) {
          return translation;
        }
      }
      // Si titleKey no está presente, no tiene traducción, o es una cadena vacía, devuelve el título normal
      return this.title;
    },
  },

}
</script>
  