<template>
  <AppNavbar @changeComponent="setCurrentComponent" />
  <div class="container">
    <div v-if="showAlert" class="modal-backdrop">
      <div class="custom-alert">
        <div class="card bg-primary-subtle">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>{{ $t('welcome') }}</h5>
            <button type="button" class="btn-close" @click="closeAlert"></button>
          </div>
          <div class="card-body">
            <img class="mx-auto d-block w-25 mb-4 mt-4" alt="App logo" src="./assets/logo.png" />
            <div class="text-center">
              <h5>{{ $t('comingSoon') }}</h5>
              <h5>{{ $t('amazing') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-4 mb-4">
    <!-- Aquí usamos :is="currentComponent" que contendrá el componente cargado dinámicamente -->
    <component :is="currentComponent"></component>
  </div>
  <AppFooter />
</template>

<script>
import { markRaw } from 'vue';

import AppNavbar from './components/AppNavbar.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
  },
  data() {
    return {
      currentComponent: null, // Se inicia como null y se actualiza dinámicamente
      showAlert: true,
    };
  },
  methods: {
    setCurrentComponent(componentName) {
      import(`./components/${componentName}.vue`)
        .then((comp) => {
          this.currentComponent = markRaw(comp.default);
        })
        .catch(error => {
          console.error("Error loading the component:", error);
          // Manejo del error
        });
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeAlert();
    }, 10000);
    // Pre-carga del componente inicial (por ejemplo, AppHome)
    this.setCurrentComponent('AppHome');
  },
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-alert {
  position: relative;
  z-index: 1050;
}
</style>
