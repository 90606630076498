import { createStore } from 'vuex';
import { createI18n } from 'vue-i18n';

// Importa las traducciones
import en from './locales/en.json';
import es from './locales/es.json';
import zhcn from './locales/zhcn.json';

// Detectar el idioma del navegador
const browserLanguage = navigator.language || navigator.userLanguage;
const defaultLanguage = browserLanguage.startsWith('es') ? 'es' : 'en';

// Configuración inicial de i18n con las traducciones específicas
const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: 'en',
  messages: {
    en,
    es,
    zhcn
  },
});

const store = createStore({
  // El resto del store permanece igual
});

export { store, i18n };
