<template>
    <li class="nav-item px-2 dropdown">
      <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-translate"></i> {{ languageName(currentLanguage) }}
      </a>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><a class="dropdown-item" @click="setLanguage('auto')">Auto</a></li>
        <li><a class="dropdown-item" @click="setLanguage('en')">English</a></li>
        <li><a class="dropdown-item" @click="setLanguage('es')">Español</a></li>
        <li><a class="dropdown-item" @click="setLanguage('zhcn')">简体中文</a></li>
      </ul>
    </li>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      currentLanguage: 'auto',
    }
  },
  created() {
    this.currentLanguage = localStorage.getItem('selectedLanguage') || 'auto';
    this.applyLanguage(this.currentLanguage);
  },
  methods: {
    applyLanguage(language) {
      if (language === 'auto') {
        this.setBrowserLanguage();
      } else {
        this.$i18n.locale = language;
      }
      localStorage.setItem('selectedLanguage', language);
    },
    setLanguage(language) {
      this.currentLanguage = language;
      this.applyLanguage(language);
    },
    setBrowserLanguage() {
      const language = navigator.language || navigator.userLanguage; 
      this.$i18n.locale = language.startsWith('es') ? 'es' : 'en';
    },
    languageName(code) {
      const languageMap = {
        auto: 'Auto',
        en: 'English',
        es: 'Español',
        zhcn: '简体中文',
      };
      return languageMap[code];
    }
  }
}
</script>
