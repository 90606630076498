<template>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Abraham's assistant <i class="bi bi-person-workspace"></i>
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <input type="text" class="form-control">
          </div>
          <button class="btn btn-outline-primary w-100"><i class="bi bi-send"></i></button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"><i
              class="bi bi-arrow-left"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
    name: 'ModalChat',
}
</script>
<style scoped>
</style>