<!-- DropdownItem.vue -->
<template>
  <li>
    <a class="dropdown-item" :href="href" @click="handleClick">
      <i :class="iconClass"></i> <!-- Icono dinámico -->
      <slot></slot> {{ translatedTitle }}
    </a>
  </li>
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    title: String,
    titleKey: String,
    href: String,
    onClick: Function,
    iconClass: String, // Nueva prop para la clase del icono
  },
  computed: {
      translatedTitle() {
        // Si titleKey está presente y no es una cadena vacía, intenta obtener su traducción
        if (this.titleKey) {
          const translation = this.$t(this.titleKey);
          // Asegúrate de que la traducción no sea igual a la clave misma, lo cual puede suceder si la clave no está definida en los archivos de traducción
          if (translation !== this.titleKey) {
            return translation;
          }
        }
        // Si titleKey no está presente, no tiene traducción, o es una cadena vacía, devuelve el título normal
        return this.title;
      },
    },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        event.preventDefault();
        this.onClick();
      }
    },
  },
}
</script>
