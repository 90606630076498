<template>
  <nav class="navbar navbar-expand-lg bg-custom">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><span class="text-success">dev</span><span class="text-primary">bit</span>boy</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <NavbarLink titleKey="nav.home" @click="changeComponent('AppHome')"/>
          <NavbarLink titleKey="nav.aboutMe" @click="changeComponent('AppAbout')" />
          <NavbarLink titleKey="nav.contact" @click="changeComponent('AppContact')" />
          <NavbarDropdown titleKey="nav.project">
            <DropdownItem titleKey="nav.game" href="/Game/"></DropdownItem>
          </NavbarDropdown>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <NavbarLink titleKey="nav.guess" href="#link" ><i class="bi bi-person"></i></NavbarLink>
          <NavbarLink titleKey="nav.chat" href="#link" data-bs-toggle="modal" data-bs-target="#exampleModal" />
          <ThemeSwitcher />
          <LanguageSwitcher />
        </ul>
      </div>
    </div>
    <ModalChat />
  </nav>
  
</template>
  
<script>
import ModalChat from './navbar/ModalChat.vue';
import ThemeSwitcher from './navbar/ThemeSwitcher.vue';
import LanguageSwitcher from './navbar/LanguageSwitcher.vue';
import NavbarLink from './navbar/NavbarLink.vue';
import NavbarDropdown from './navbar/NavbarDropdown.vue';
import DropdownItem from './navbar/DropdownItem.vue';


export default {
  name: 'AppNavbar',
  emits: ['changeComponent'],
  components: {
    ModalChat,
    LanguageSwitcher,
    ThemeSwitcher,
    NavbarLink,
    NavbarDropdown,
    DropdownItem
  },
  methods: {
    changeComponent(component) {
      this.$emit('changeComponent', component);
    },
  }
}
</script>
  
<style scoped>
.bg-custom {
  background-color: rgba(137, 43, 226, 0.151);
}
</style>
  